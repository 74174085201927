/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Col, FormGroup, Label, FormFeedback } from "reactstrap";
import NumberFormat from "react-number-format";

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <FormGroup className="row align-items-center">{props.children}</FormGroup>
  );
};

function NumberInput(props) {
  return (
    <React.Fragment>
      <Wrapper {...props}>
        {!props.noLabel && (
          <Col sm={2}>
            <Label>{props.label}</Label>
          </Col>
        )}
        <Col>
          <NumberFormat
            name={props.name}
            placeholder={props.placeholder}
            thousandSeparator="."
            decimalSeparator=","
            prefix={props.prefix ? props.prefix + " " : null}
            sufix={props.sufix ? props.sufix + " " : null}
            decimalScale={props.decimals || 0}
            onValueChange={(e) => props.onChange(props.name, e.value)}
            className={
              props.error
                ? "form-control border border-danger rounded is-invalid"
                : "form-control"
            }
            value={props.value}
            disabled={props.disabled || false}
            required={!props.disabled}
            invalid={Boolean(props.error)}
          />
          <FormFeedback invalid>
            {props.error || "Este campo es necesario"}
          </FormFeedback>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}
NumberInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  data: PropTypes.array,
  decimals: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  errorMessage: PropTypes.string,
  noRow: PropTypes.bool,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool,
  sufix: PropTypes.string,
};

NumberInput.defaultProps = {
  noLabel: false,
};

export default NumberInput;
