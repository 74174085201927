// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangedPassword from "pages/Authentication/ChangedPassword";
import ResetPassword from "pages/Authentication/ResetPassword";
import Forbidden from "../pages/Forbidden/index";

// Users
import UserList from "pages/Users/UserList";
import UserForm from "pages/Users/UserForm";

// Logs
import LogList from "pages/Log/LogList";
import LogForm from "pages/Log/LogForm";

// Common routes for everyone
const authProtectedRoutes = [
  { path: "/changed-password", exact: true, component: ChangedPassword },
  { path: "/user", exact: true, component: UserList },
  { path: "/user/:id", exact: true, component: UserForm },

  { path: "/logs", exact: true, component: LogList },
  { path: "/logs/see/:id", exact: true, component: LogForm },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: LogList },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:code", component: ResetPassword },
  { path: "/forbidden", component: Forbidden },
];

export { authProtectedRoutes, publicRoutes };
