import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";

import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";

//Helpers
import { PasswordInput, EmailInput, useForm } from "components/Form";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

const Login = () => {
  const [data, setData] = useState({});

  const { response, requestMessage, errors, sendForm } = useForm();

  useEffect(() => {
    const token = window.localStorage.getItem("token");
    const auth = JSON.parse(window.localStorage.getItem("authUser"));
    if (token !== null && auth !== null) {
      window.location.href = "/";
    }
  }, []);

  useEffect(() => {
    if (!response.token) return;

    localStorage.setItem("token", response.token);
    localStorage.setItem("authUser", JSON.stringify(response.user));
    window.location = "/";
  }, [response]);

  const handleChangeInput = async (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>{process.env.REACT_APP_PAGE_TITLE} | Login</title>
      </MetaTags>

      <Container fluid>
        <div className="home-btn">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>

        <Row className="justify-content-center">
          <Col md={8} lg={6} className="col-xxl-4">
            <Card className="overflow-hidden login_fondo">
              <CardHeader className="p-3 d-flex flex-column flex-md-row gap-2 align-items-end justify-content-center">
                <h1 className="mb-0 text-white">Logger</h1>
                <span className="text-white">By Usercode</span>
              </CardHeader>
              <CardBody className="">
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={() => sendForm("login", data, null)}
                >
                  <EmailInput
                    name="email"
                    label="Email"
                    data={data.email}
                    onChange={handleChangeInput}
                    placeholder="Ingrese su email"
                    error={errors.email}
                  />
                  <PasswordInput
                    name="password"
                    label="Password"
                    onChange={handleChangeInput}
                    data={data.password}
                    placeholder="Ingrese su contraseña"
                    error={errors.password}
                  />
                  {errors && <Alert color="danger">{requestMessage}</Alert>}
                  <div className="mt-3 d-grid">
                    <button
                      className="btn bg-login-footer text-white btn-block"
                      type="submit"
                    >
                      Ingresar
                    </button>
                  </div>
                  <div className="mt-4 text-center">
                    <Link
                      to="/forgot-password"
                      className="btn"
                      id="login_forgotpassword"
                    >
                      <i className="mdi mdi-lock me-1" /> Olvidó su contraseña?
                    </Link>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
