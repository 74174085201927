/* eslint-disable react/prop-types */
import React from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { AvGroup } from "availity-reactstrap-validation";
import { Row, Col, Label, Input } from "reactstrap";

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <Row className="align-items-center">{props.children}</Row>
  );
};

function CheckInput(props) {
  return (
    <React.Fragment>
      <Wrapper {...props}>
        {!props.noLabel && (
          <Col sm={2}>
            <Label>{props.label}</Label>
          </Col>
        )}
        <Col>
          <Row md={props.inline ? 1 : 4} className="text-nowrap gy-2">
            {props.content.map((item, idx) => (
              <Col key={idx}>
                <AvGroup check inline>
                  <Label check>{item.label}</Label>
                  <Input
                    name={props.name}
                    onChange={(e) => props.onChange(props.name, item.value)}
                    checked={props.values?.includes(item.value)}
                    placeholder={props.placeholder || "Ingrese un valor..."}
                    type="checkbox"
                    //Invested securities
                    // errorMessage={props.errorMessage || "Campo inválido"}
                    disabled={props.disabled || false}
                    //Normal values
                    required={
                      props.edit === true || props.edit === undefined
                        ? true
                        : false
                    }
                    validate={{
                      required: {
                        value: props.required,
                        errorMessage:
                          props.errorMessage || "Este campo es necesario",
                      },
                    }}
                  />
                </AvGroup>
              </Col>
            ))}
          </Row>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}

CheckInput.propTypes = {
  //match: PropTypes.object.isRequired,
  //location: PropTypes.object.isRequired,
  //history: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.array,
  errorMessage: PropTypes.string,
  noRow: PropTypes.bool,
  noLabel: PropTypes.bool,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  content: PropTypes.array,
};

CheckInput.defaultProps = {
  noLabel: false,
};

export default withRouter(CheckInput);
