import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

function Unauthorized() {
  const user = JSON.parse(window.localStorage.getItem("authUser"));

  return user ? <Redirect to="/forbidden" /> : <Redirect to="/login" />;
}

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles,
  ...rest
}) => {
  /*
   * Test if the logged user have any of the given values.
   *
   * @param {Array} roles The roles to thes if the user have it.
   * @return {bool} true if the user have at least one of the given roles.
   */
  const rolesTest = (testRoles, component) => {
    const user = JSON.parse(window.localStorage.getItem("authUser"));

    const roles = user.roles.map((item) => item.role);

    // Return true if at least one role match, false if any role match
    const bool = testRoles.some((item) => roles.includes(item));

    console.log({ testRoles, bool });

    return bool ? component : <Unauthorized />;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return <Unauthorized />;
        }

        if (roles.length > 0) {
          return <Layout>{rolesTest(roles, <Component {...props} />)}</Layout>;
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  roles: PropTypes.array,
};

AppRoute.defaultTypes = {
  roles: [],
};

export default AppRoute;
