/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { AvGroup, AvInput } from "availity-reactstrap-validation";
import { Col, FormFeedback, Label } from "reactstrap";

const Wrapper = (props) => {
  return props.noRow ? (
    <React.Fragment>{props.children}</React.Fragment>
  ) : (
    <AvGroup className="row align-items-center">{props.children}</AvGroup>
  );
};

function TextAreaInput(props) {
  return (
    <React.Fragment>
      <Wrapper {...props}>
        <Col sm={props.noRow ? 1 : 2}>
          <Label>{props.label}</Label>
        </Col>
        <Col>
          <AvInput
            name={props.name}
            onChange={(e) => props.onChange(props.name, e.target.value)}
            value={props.value || ""}
            placeholder={props.placeholder || "Ingrese un valor..."}
            type="textarea"
            disabled={props.disabled || false}
            required={!props.disabled && props.required}
            invalid={Boolean(props.error)}
          />
          <FormFeedback invalid>
            {props.error || "Este campo es necesario"}
          </FormFeedback>
        </Col>
      </Wrapper>
    </React.Fragment>
  );
}

TextAreaInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  noRow: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

TextAreaInput.defaultProps = {
  required: true,
  disabled: false,
};

export default TextAreaInput;
