/* eslint-disable jsx-a11y/role-supports-aria-props */

import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { checkRole } from "helpers/getUser";

class SidebarAdmin extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate() {
    this.initMenu();
  }

  initMenu() {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (
        // HACK: don't pick the parent element
        !items[i].classList.contains("has-arrow") &&
        this.props.location.pathname === items[i].pathname
      ) {
        matchingMenuItem = items[i];
      } else {
        this.deactivateParentDropdown(items[i]);
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = (item) => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  deactivateParentDropdown = (item) => {
    item.classList.remove("mm-active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.remove("mm-show");
    }

    if (parent) {
      parent.classList.remove("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.remove("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.remove("mm-active"); // li
          parent3.childNodes[0].classList.remove("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.remove("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.remove("mm-show"); // li
              parent5.childNodes[0].classList.remove("mm-active"); // a tag
            }
          }
        }
      }
    }
  };

  sendGA(item) {
    window.gtag("event", "Click", {
      event_category: "Menú",
      event_label: item,
    });
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              <li>
                <Link to="/" className="" onClick={() => this.sendGA("Inicio")}>
                  <i className="fas fa-home" />
                  <span>{this.props.t("Inicio")}</span>
                </Link>
              </li>

              {checkRole(["Administrador"]) && (
                <li>
                  <Link
                    to="/users"
                    className=""
                    onClick={() => this.sendGA("Usuarios")}
                  >
                    <i className="fas fa-user-circle" />
                    <span>{this.props.t("Usuarios")}</span>
                  </Link>
                </li>
              )}

              <li>
                <Link
                  to="/product"
                  className=""
                  onClick={() => this.sendGA("Productos")}
                >
                  <i className="fas fa-bath" />
                  <span>{this.props.t("Productos")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/prototypes"
                  className=""
                  onClick={() => this.sendGA("Prototipo y Diseño")}
                >
                  <i className="fas fa-box-open" />
                  <span>{this.props.t("Prototipo y Diseño")}</span>
                </Link>
              </li>

              {checkRole(["Administrador"]) && (
                <li>
                  <Link className="has-arrow">
                    <i className="fas fa-cogs" />
                    <span>{this.props.t("Mantenedores")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to="/supplier"
                        className=""
                        onClick={() => this.sendGA("Proveedor")}
                      >
                        Proveedor
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/brand"
                        className=""
                        onClick={() => this.sendGA("Marca")}
                      >
                        Marca
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/model"
                        className=""
                        onClick={() => this.sendGA("Modelo")}
                      >
                        Modelo
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/state"
                        className=""
                        onClick={() => this.sendGA("Estado")}
                      >
                        Estado
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/element-type"
                        className=""
                        onClick={() => this.sendGA("Tipo de Elementos")}
                      >
                        Tipo de Elementos
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/works"
                        className=""
                        onClick={() => this.sendGA("Proyectos Iconstruye")}
                      >
                        Proyectos Iconstruye
                      </Link>
                    </li>

                    <li>
                      {/* eslint-disable-next-line */}
                      <a>
                        <hr />
                      </a>
                    </li>

                    <li>
                      <Link
                        to="/family"
                        className=""
                        onClick={() => this.sendGA("Familia")}
                      >
                        {this.props.t("Familia")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/class"
                        className=""
                        onClick={() => this.sendGA("Clase")}
                      >
                        Clase
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/subclass"
                        className=""
                        onClick={() => this.sendGA("Subclase")}
                      >
                        Subclase
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/element"
                        className=""
                        onClick={() => this.sendGA("Elemento")}
                      >
                        Elemento
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/component"
                        className=""
                        onClick={() => this.sendGA("Componente")}
                      >
                        Componente
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {checkRole([
                "Administrador",
                "Inmobiliaria",
                "Gerencia Presupuesto",
                "Gerencia ODI",
                "ODI",
                "Calidad",
                "Gerencia",
                "Arquitectura",
                "Construcción",
              ]) && (
                <li>
                  <Link to="/matrices" className="has-arrow">
                    <i className="fas fa-th-large" />
                    <span>{this.props.t("Matrices")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to="/matrices/hight"
                        className=""
                        onClick={() => this.sendGA("Seg. Alto")}
                      >
                        Seg. Alto
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/matrices/middle-hight"
                        className=""
                        onClick={() => this.sendGA("Seg. Medio Alto")}
                      >
                        Seg. Medio Alto
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/matrices/middle"
                        className=""
                        onClick={() => this.sendGA("Seg. Medio")}
                      >
                        Seg. Medio
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/matrices/middle-low"
                        className=""
                        onClick={() => this.sendGA("Seg. Medio Bajo")}
                      >
                        Seg. Medio Bajo
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/matrices/low"
                        className=""
                        onClick={() => this.sendGA("Seg. Bajo")}
                      >
                        Seg. Bajo
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {checkRole([
                "Administrador",
                "Inmobiliaria",
                "Gerencia Presupuesto",
                "Gerencia ODI",
                "ODI",
                "Calidad",
                "Gerencia",
                "Arquitectura",
                "Construcción",
              ]) && (
                <li>
                  <Link
                    to="/projects"
                    className=""
                    onClick={() => this.sendGA("Proyectos")}
                  >
                    <i className="fas fa-calculator" />
                    <span>{this.props.t("Proyectos")}</span>
                  </Link>
                </li>
              )}

              {checkRole(["Administrador"]) && (
                <li>
                  <Link
                    to="/history"
                    className=""
                    onClick={() => this.sendGA("Historial")}
                  >
                    <i className="fas fa-clock" />
                    <span>{this.props.t("Historial")}</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarAdmin.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarAdmin));
