import { useCallback, useState } from "react";
import CheckInput from "./CheckInput";
import FileInput from "./FileInput";
import NumberInput from "./NumberInput";
import SelectInput, { useFetchSelect } from "./SelectInput";
import TextAreaInput from "./TextAreaInput";
import TextInput from "./TextInput";
import PasswordInput from "./PasswordInput";
import EmailInput from "./EmailInput";
import DateInput from "./DateInput";
import { post, put } from "../../helpers/ApiHelper";

const useForm = () => {
  const [response, setResponse] = useState({});
  const [requestMessage, setRequestMessage] = useState("");
  const [errors, setErrors] = useState(false);

  const clearMsg = useCallback(() => {
    if (requestMessage) setRequestMessage("");
  }, [requestMessage]);

  const sendForm = async (endpoint, data, id) => {
    setRequestMessage("");
    const response = id
      ? await put(endpoint + "/" + id, data)
      : await post(endpoint, data);

    setResponse(response.status === 200 ? response.data : {});
    setErrors(response.status !== 200 ? response.data : false);
    setRequestMessage(response.message);
  };

  return { response, requestMessage, errors, sendForm, clearMsg };
};

export {
  CheckInput,
  DateInput,
  EmailInput,
  FileInput,
  NumberInput,
  PasswordInput,
  SelectInput,
  TextAreaInput,
  TextInput,
  useFetchSelect,
  useForm,
};
